export { validatePromoCode } from './model/services/validatePromoCode';
export { fetchFormPayment } from './model/services/fetchFormPayment';
export {
  formPaymentReducer,
  setErrorFormPayment,
} from './model/slices/formPayment';
export { updateForm, setPromoCode } from './model/slices/formPayment';
export { FormPayment } from './ui/FormPayment';
export type { FormPaymentSchema } from './model/types/formPayment';
export {
  getFormPaymentIsLoading,
  getErrorTelFormPayment,
  getErrorCodeFormPayment,
  getErrorPackFormPayment,
  getRequestPhoneFormPayment,
  getRequestEmailFormPayment,
  getRequestNameFormPayment,
  getRequestPromoCodeFormPayment,
  getRequestLicenseFormPayment,
  getRequestAdvertFormPayment,
  getErrorEmailFormPayment,
  getErrorNameFormPayment,
  getOldPricePromo,
  getPricePromo,
} from './model/selectors/getFormPayment';
