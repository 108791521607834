import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setPromoCode } from '../slices/formPayment';
import { IPromoCodeRequest, IPromoCodeResponse } from '../types/formPayment';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const validatePromoCode = createAsyncThunk<
  IPromoCodeResponse,
  IPromoCodeRequest,
  ThunkConfig<string>
>('formPayment/validatePromoCode', async (promoData, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IPromoCodeResponse>(
      urls.promo.validatePromoCode,
      promoData,
    );

    if (response.data.errorPromoCode)
      throw new Error(response.data.errorPromoCode);

    dispatch(
      setPromoCode({
        oldPrice: response.data.oldPrice,
        price: String(response.data.price),
      }),
    );

    return response.data;
  } catch (error) {
    const { message } = error as AxiosError;
    setPromoCode({ oldPrice: '', price: '' });
    return rejectWithValue(message);
  }
});
