import { createAsyncThunk } from '@reduxjs/toolkit';
import { Package, QueryParamsForAllPackages } from '../../types/package';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export interface ResponsePayloadFetch {
  data: Package[];
  productType: QueryParamsForAllPackages;
  isPartner: boolean;
}

interface RequestPayloadFetch extends Omit<ResponsePayloadFetch, 'data'> {}

export const fetchPackage = createAsyncThunk<
  ResponsePayloadFetch,
  RequestPayloadFetch,
  ThunkConfig<string>
>('package/allPackage', async ({ isPartner, productType }, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<Package[]>(urls.markets.getMarkets, {
      isPartnerPromo: isPartner,
      productType,
    });

    if (!response.data) throw new Error();

    return { data: response.data, isPartner, productType };
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
