import { StateSchema } from '@/app-fsd/providers/StoreProvider';

export const getPackagesPAIsLoading = (state: StateSchema) =>
  state.packagesPA.isLoading;

export const getPackagesPAError = (state: StateSchema) =>
  state.packagesPA?.error;

export const getPackagesPAData = (state: StateSchema) =>
  state.packagesPA.packages;
