import { Listbox } from '@headlessui/react';
import { FC, Fragment, ReactNode, memo, useCallback, useMemo } from 'react';
import cls from './select.module.css';
import popupCls from '../../../../styles/popups.module.css';
import { Icon } from '../../../Icon';
import { Input } from '../../../Inputs';
import clsInput from '../../../Inputs/model/styles/inputStyles.module.css';
import { VStack } from '../../../Stack';
import { Text, Variants } from '../../../Text';
import { DownOutlinedSVG, UpOutlinedSVG } from '@/shared/assets/svg/Additional';
import { classNames } from '@/shared/lib/classNames/classNames';
import { mapDirectionClass, mapMarginsSize } from '@/shared/styles/const';
import { DropDownDirection, Margins } from '@/shared/types/ui';

export interface SelectItem {
  content: ReactNode;
  disabled?: boolean;
  value: string;
  variant?: Variants;
}

export interface ISelectProps {
  className?: string;
  classNameInput?: string;
  classNamePopup?: string;
  defaultValue?: string;
  direction?: DropDownDirection;
  items: SelectItem[];
  label?: string;
  onChange: (value: string) => void;
  readonly?: boolean;
  value?: string;
  placeholder?: string;
  fullWidth?: boolean;
  wrapperClass?: string;
  triggerContent?: ReactNode;
  size?: 'large' | 'small';
  minWidth?: Margins;
  variant?: 'primary' | 'secondary';
}

export const Select: FC<ISelectProps> = memo(props => {
  const {
    className,
    classNameInput,
    classNamePopup,
    defaultValue,
    direction = 'bottom left',
    items,
    label,
    onChange,
    readonly,
    wrapperClass,
    value,
    placeholder,
    fullWidth,
    triggerContent,
    size = 'large',
    minWidth = 'x-s',
    variant = 'primary',
  } = props;

  const currentClassNamePopup = useMemo(
    () =>
      classNames(popupCls.popup, { [clsInput['full-width']]: fullWidth }, [
        className,
      ]),
    [className, fullWidth],
  );

  const classNameInputSelect = useMemo(
    () =>
      classNames(clsInput.input, { [clsInput['full-width']]: fullWidth }, [
        cls['input-select'],
        classNameInput,
        popupCls[size],
      ]),
    [fullWidth, size, classNameInput],
  );

  const classNameSelect = useMemo(
    () =>
      classNames(popupCls.menu, {}, [
        mapDirectionClass[direction],
        classNamePopup,
      ]),
    [classNamePopup, direction],
  );

  const optionList = useMemo(
    () =>
      items.map(item => (
        <Listbox.Option
          key={item.value}
          value={item.value}
          as={Fragment}
          disabled={item.disabled}
        >
          {({ active, selected }) => (
            <Text
              variant={item.variant || 'body-m'}
              className={classNames(
                popupCls.item,
                {
                  [popupCls.active]: active,
                  [popupCls.disabled]: item.disabled,
                  [popupCls.selected]: selected && variant === 'primary',
                  [cls.item]: variant === 'secondary',
                  [cls.selected]: selected && variant === 'secondary',
                },
                [popupCls[size]],
              )}
            >
              {item.content}
            </Text>
          )}
        </Listbox.Option>
      )),
    [items, size, variant],
  );

  const currentTriggerContent = useCallback(
    (open: boolean) =>
      triggerContent || (
        <Input
          value={value || defaultValue}
          placeholder={placeholder}
          fullWidth={fullWidth}
          className={classNameInputSelect}
          // style={{ minWidth: `${mapMarginsSize[minWidth]}rem` }}
          endIcon={
            open ? <Icon Svg={UpOutlinedSVG} /> : <Icon Svg={DownOutlinedSVG} />
          }
        />
      ),
    [
      defaultValue,
      // minWidth,
      placeholder,
      triggerContent,
      value,
      classNameInputSelect,
      fullWidth,
    ],
  );

  return (
    <VStack
      className={classNames('', { [clsInput['full-width']]: fullWidth }, [
        wrapperClass,
        cls[variant],
      ])}
    >
      {!!label && (
        <label htmlFor={label || 'select'} className={clsInput.label}>
          {label}
        </label>
      )}
      <Listbox
        as='div'
        disabled={readonly}
        value={value}
        id={label || 'select'}
        className={currentClassNamePopup}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <Listbox.Button as='div' className={popupCls.trigger}>
              {currentTriggerContent(open)}
            </Listbox.Button>
            <Listbox.Options
              className={classNameSelect}
              style={{ minWidth: `${mapMarginsSize[minWidth]}rem` }}
            >
              {optionList}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </VStack>
  );
});
