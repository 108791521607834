import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchPackage,
  ResponsePayloadFetch,
} from '../services/allPackages/fetchPackage';
import { discountPackages } from '../services/discountPackages/discountPackages';
import { Package, PackageSchema, Product } from '../types/package';

const initialState: PackageSchema = {
  allCopytradingPackages: [],
  allPromoScreenerPackages: [],
  allPromoSignalsPackages: [],
  allScreenerPackages: [],
  allSignalsPackages: [],
  isLoadingPackages: false,
  selectedPackage: {
    id: -1,
    name: 'Forex',
    products: [],
    tradeInstruments: [],
  },
  selectedProduct: {
    duration: -1,
    id: -1,
    isTrial: false,
    marketName: 'Forex',
    price: '',
    title: '',
    type: 'main',
  },
};

export const packageSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(discountPackages.pending, state => {
        state.errorPackages = undefined;
        state.isLoadingPackages = true;
      })
      .addCase(
        discountPackages.fulfilled,
        (state, { payload }: PayloadAction<Package[]>) => {
          state.isLoadingPackages = false;
        },
      )
      .addCase(discountPackages.rejected, (state, { payload }) => {
        state.isLoadingPackages = false;
        state.errorPackages = payload;
      })
      .addCase(fetchPackage.pending, state => {
        state.errorPackages = undefined;
        state.isLoadingPackages = true;
      })
      .addCase(
        fetchPackage.fulfilled,
        (
          state,
          {
            payload: { data, productType, isPartner },
          }: PayloadAction<ResponsePayloadFetch>,
        ) => {
          if (productType === 'copy-trading')
            state.allCopytradingPackages = data;
          if (productType === 'main')
            isPartner
              ? (state.allPromoSignalsPackages = data)
              : (state.allSignalsPackages = data);
          if (productType === 'external')
            isPartner
              ? (state.allPromoScreenerPackages = data)
              : (state.allScreenerPackages = data);
          state.isLoadingPackages = false;
        },
      )
      .addCase(fetchPackage.rejected, (state, { payload }) => {
        state.errorPackages = payload;
      });
  },
  initialState,
  name: 'package',
  reducers: {
    setFieldSelectedPackages: (
      state,
      { payload }: PayloadAction<{ oldPrice?: string; price: string }>,
    ) => {
      if (state.selectedPackage)
        state.selectedPackage = { ...state.selectedPackage, ...payload };
    },
    setIsLoadingPackages: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingPackages = payload;
    },
    setResetSelectedPackages: state => {
      state.selectedPackage = initialState.selectedPackage;
    },
    setResetSelectedProduct: state => {
      state.selectedProduct = initialState.selectedProduct;
    },
    setSelectedPackages: (state, { payload }: PayloadAction<Package>) => {
      state.selectedPackage = payload;
    },
    setSelectedProduct: (state, { payload }: PayloadAction<Product>) => {
      state.selectedProduct = payload;
    },
  },
});

export const {
  actions: {
    setSelectedPackages,
    setSelectedProduct,
    setIsLoadingPackages,
    setFieldSelectedPackages,
    setResetSelectedPackages,
    setResetSelectedProduct,
  },
} = packageSlice;

export const { reducer: packageReducer } = packageSlice;
