import { CountryCode } from 'libphonenumber-js/types';
import { useTranslations } from 'next-intl';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import ReactGA from 'react-ga4';
import cls from './FormPayment.module.css';
import {
  getRequestPhoneFormPayment,
  getRequestEmailFormPayment,
  getRequestNameFormPayment,
  getRequestPromoCodeFormPayment,
  getRequestLicenseFormPayment,
  getRequestAdvertFormPayment,
  getErrorCodeFormPayment,
  getErrorPackFormPayment,
  getErrorTelFormPayment,
  getErrorEmailFormPayment,
  getErrorNameFormPayment,
  getRequestFormPayment,
  getFormPaymentIsLoading,
} from '../model/selectors/getFormPayment';
import { fetchFormPayment } from '../model/services/fetchFormPayment';
import { validatePromoCode } from '../model/services/validatePromoCode';
import { setErrorFormPayment, updateForm } from '../model/slices/formPayment';
import { getSelectedProduct } from '@/entities/Package';
import { getGeolocationUser } from '@/entities/User';
import {
  getRoutePaymentStep2,
  getRoutePrivacyPolicy,
  getRouteTermsOfUse,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useQueryParams } from '@/shared/lib/hooks/useQueryParams';
import { Checkbox, Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { PhoneInputMask } from '@/shared/ui/Inputs/ui/PhoneInputMask/PhoneInputMask';
import { VStack, Flex } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface IFormPaymentProps {
  className?: string;
  idSelectedProduct?: number;
}

export const FormPayment: FC<IFormPaymentProps> = memo(props => {
  const { className, idSelectedProduct } = props;
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const searchParams = useQueryParams();

  const lang = (localStorage.getItem('i18nextLng') as 'ru' | 'en') || 'ru';
  const { href } = window.location;

  const { isDesktop, isMobile } = useDetectDevice();

  const { push } = useAppNavigation();

  const promoTextIcon = (
    <Text variant='body-m' style={{ marginTop: 2 }}>
      {t('if you have one')}
    </Text>
  );

  const selectedProduct = useAppSelector(getSelectedProduct);

  const geoLoc = useAppSelector(getGeolocationUser);
  const geoLocation = geoLoc as CountryCode;

  // inputs
  const telephone = useAppSelector(getRequestPhoneFormPayment);
  const email = useAppSelector(getRequestEmailFormPayment);
  const name = useAppSelector(getRequestNameFormPayment);
  const promoCode = useAppSelector(getRequestPromoCodeFormPayment);
  const isLicenseAllowLocal = useAppSelector(getRequestLicenseFormPayment);
  const isAdvertAllowLocal = useAppSelector(getRequestAdvertFormPayment);

  // for send form
  const formDataRequest = useAppSelector(getRequestFormPayment);
  const isLoadingRequest = useAppSelector(getFormPaymentIsLoading);

  // errors
  const errorTel = useAppSelector(getErrorTelFormPayment);
  const errorCode = useAppSelector(getErrorCodeFormPayment);
  const errorPack = useAppSelector(getErrorPackFormPayment);
  const errorEmail = useAppSelector(getErrorEmailFormPayment);
  const errorName = useAppSelector(getErrorNameFormPayment);

  // flag disable button
  const disableButton = useMemo(
    () =>
      !telephone ||
      !email ||
      !name ||
      !isLicenseAllowLocal ||
      !!errorCode ||
      !!errorTel ||
      !!errorPack ||
      !!errorEmail ||
      !!errorName ||
      !isAdvertAllowLocal,
    [
      email,
      errorCode,
      errorEmail,
      errorName,
      errorPack,
      errorTel,
      isAdvertAllowLocal,
      isLicenseAllowLocal,
      name,
      telephone,
    ],
  );

  const redirectToTerms = () => push({ path: getRouteTermsOfUse() });

  const redirectToPolicy = () => push({ path: getRoutePrivacyPolicy() });

  // for change state inputs
  const onChangePhoneNumber = useCallback(
    (value?: string) => dispatch(updateForm({ phoneNumber: value || '' })),
    [dispatch],
  );
  const onChangeEmail = useCallback(
    (value?: string) => dispatch(updateForm({ email: value || '' })),
    [dispatch],
  );
  const onChangeName = useCallback(
    (value?: string) => dispatch(updateForm({ name: value || '' })),
    [dispatch],
  );
  const onChangePromoCode = useCallback(
    (value?: string) => dispatch(updateForm({ promoCode: value || '' })),
    [dispatch],
  );
  const onBlurPromoCode = useCallback(
    (event?: FocusEvent<HTMLInputElement>) => {
      const promo = event?.target.value;
      if (promo && selectedProduct?.id)
        dispatch(
          validatePromoCode({
            productId: selectedProduct?.id,
            promoCode: promo,
          }),
        );
    },
    [dispatch, selectedProduct?.id],
  );
  const onChangeLicense = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      dispatch(updateForm({ isLicenseAllow: event.target.checked })),
    [dispatch],
  );
  const onChangeAdvert = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      dispatch(updateForm({ isAdvertAllow: event.target.checked })),
    [dispatch],
  );

  // for change state errors
  const onChangeErrorTel = useCallback(
    (value?: string) =>
      dispatch(setErrorFormPayment({ errorTel: value || '' })),
    [dispatch],
  );
  const onChangeErrorEmail = useCallback(
    (value?: string) =>
      dispatch(setErrorFormPayment({ errorEmail: value || '' })),
    [dispatch],
  );
  const onChangeErrorName = useCallback(
    (value?: string) =>
      dispatch(setErrorFormPayment({ errorName: value || '' })),
    [dispatch],
  );
  const onChangeErrorCode = useCallback(
    (value?: string) =>
      dispatch(setErrorFormPayment({ errorCode: value || '' })),
    [dispatch],
  );

  // items checkboxes
  const itemsCheckboxes = useMemo(
    () => [
      {
        children: (
          <Text variant='body-s'>
            {t(
              'I acknowledge that by applying for this paid subscription service, I am agreeing to',
            )}
            <Text
              className={cls['green-text']}
              variant='body-s'
              as='span'
              onClick={redirectToPolicy}
            >
              {t('the Privacy Policy')}
            </Text>
            {t('and')}
            <Text
              className={cls['green-text']}
              variant='body-s'
              as='span'
              onClick={redirectToTerms}
            >
              {t('License Agreement')}
            </Text>
          </Text>
        ),
        id: 'isLicenseAllowLocal',
        onChange: onChangeLicense,
        value: isLicenseAllowLocal,
      },
      {
        children: (
          <Text variant='body-s'>
            {t(
              'I consent to receiving informational materials to acquaint myself with and to receive the most beneficial offers and discounts',
            )}
          </Text>
        ),
        id: 'isAdvertAllow',
        onChange: onChangeAdvert,
        value: isAdvertAllowLocal,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      onChangeLicense,
      isLicenseAllowLocal,
      onChangeAdvert,
      isAdvertAllowLocal,
    ],
  );

  const handleSubmit = async () => {
    const response = await dispatch(
      fetchFormPayment({
        ...formDataRequest,
        lang,
        partnerId: Number(searchParams.ref),
        productId: idSelectedProduct,
        utmCampaign: searchParams.utm_campaign,
        utmContent: searchParams.utm_content,
        utmMedium: searchParams.utm_medium,
        utmSource: searchParams.utm_source,
        utmTerm: searchParams.utm_term,
      }),
    );

    if (response.meta.requestStatus === 'fulfilled') {
      ReactGA.event('gtm.formSubmit', {
        form_ID: 'lead',
        form_Text: selectedProduct?.title,
        form_Url: href,
      });
      push({ path: getRoutePaymentStep2() });
    }
  };

  useEffect(() => {
    if ((searchParams.promo || promoCode) && selectedProduct?.id) {
      onChangePromoCode(searchParams.promo || promoCode);
      dispatch(
        validatePromoCode({
          productId: selectedProduct?.id,
          promoCode: searchParams.promo || promoCode,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams.promo, selectedProduct?.id]);

  return (
    <VStack
      max
      justify='between'
      className={classNames(cls['form-payment'], {}, [className])}
    >
      <VStack max className={cls.wrapper}>
        <VStack max className={cls.title} align='center'>
          {!isMobile && (
            <Text as='h3' variant='h3' align='center'>
              {t('Personal information')}
            </Text>
          )}
          <Text
            variant={isMobile ? 'h3' : 'body-m'}
            align='center'
            className={cls['form-description']}
          >
            {t('To subscribe add your personal information')}
          </Text>
        </VStack>
        <Flex
          max
          direction={!isMobile ? 'row' : 'column'}
          className={cls.inputs}
          align='start'
        >
          <PhoneInputMask
            required
            isValidate
            fullWidth
            name='tel'
            min={7}
            max={15}
            geoLocation={geoLocation}
            value={telephone}
            label={t('Phone number')}
            placeholder={t('Enter phone number')}
            data-testid='FormPayment.tel'
            error={errorTel}
            isError={telephone === ''}
            setError={onChangeErrorTel}
            classNameWrapper={cls['input-wrapper']}
            onChange={onChangePhoneNumber}
          />
          <Input
            required
            isValidate
            fullWidth
            type='email'
            name='email'
            value={email}
            label={t('Email address')}
            placeholder={t('Enter email address')}
            data-testid='FormPayment.email'
            error={errorEmail}
            isError={email === ''}
            setError={onChangeErrorEmail}
            classNameWrapper={cls['input-wrapper']}
            onChange={onChangeEmail}
          />
        </Flex>
        <Flex
          max
          direction={!isMobile ? 'row' : 'column'}
          className={cls.inputs}
          align='start'
        >
          <Input
            required
            isValidate
            fullWidth
            name='name'
            value={name}
            label={t('Name')}
            placeholder={t('Enter name')}
            error={errorName}
            setError={onChangeErrorName}
            data-testid='FormPayment.name'
            classNameWrapper={cls['input-wrapper']}
            onChange={onChangeName}
          />
          <Input
            isValidate
            fullWidth
            name='code'
            label={t('Promo code')}
            placeholder='xxxxx'
            autoComplete='off'
            error={errorCode}
            value={promoCode}
            setError={onChangeErrorCode}
            endIcon={promoTextIcon}
            endIconClass={cls['end-icon']}
            classNameWrapper={cls['input-wrapper']}
            onChange={onChangePromoCode}
            onBlur={onBlurPromoCode}
          />
        </Flex>
        {itemsCheckboxes.map(item => (
          <Checkbox
            key={item.id}
            checked={item.value}
            id={item.id}
            className={cls.checkbox}
            onChange={item.onChange}
          >
            {item.children}
          </Checkbox>
        ))}
      </VStack>
      <Button
        fullWidth
        size={isDesktop ? 'default' : 'small'}
        data-testid='FormPayment.pay'
        text={t('Pay now')}
        isLoading={isLoadingRequest}
        disabled={disableButton}
        onClick={handleSubmit}
      />
    </VStack>
  );
});
