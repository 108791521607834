import { StateSchema } from '@/app-fsd/providers/StoreProvider';

export const getPackageIsLoading = (state: StateSchema) =>
  state?.packages?.isLoadingPackages;

export const getSelectedPackage = (state: StateSchema) =>
  state?.packages?.selectedPackage;

export const getSelectedProduct = (state: StateSchema) =>
  state?.packages?.selectedProduct;

export const getAllCopytradingPackages = (state: StateSchema) =>
  state?.packages?.allCopytradingPackages;

export const getAllScreenerPackages = (state: StateSchema) =>
  state?.packages?.allScreenerPackages;

export const getAllSignalsPackages = (state: StateSchema) =>
  state?.packages?.allSignalsPackages;

export const getAllPromoSignalsPackages = (state: StateSchema) =>
  state?.packages?.allPromoSignalsPackages;

export const getAllPromoScreenerPackages = (state: StateSchema) =>
  state?.packages?.allPromoScreenerPackages;
