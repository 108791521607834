import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPackagesAllowed } from '../services/getPackagesAllowed';
import { IPackagesPA, IPackagesPASchema } from '../types/package';

const initialState: IPackagesPASchema = {
  error: '',
  isLoading: false,
  packages: [],
};

export const packagesAllowedSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getPackagesAllowed.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getPackagesAllowed.fulfilled,
        (state, { payload }: PayloadAction<IPackagesPA[]>) => {
          state.isLoading = false;
          state.packages = payload;
        },
      )
      .addCase(getPackagesAllowed.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'packagesAllowed',
  reducers: {
    setErrorPackagesAllowed: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsLoadingPackagesAllowed: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoading = payload;
    },
    setPackagesAllowed: (state, { payload }: PayloadAction<IPackagesPA[]>) => {
      state.packages = payload;
    },
  },
});

export const {
  actions: {
    setErrorPackagesAllowed,
    setIsLoadingPackagesAllowed,
    setPackagesAllowed,
  },
} = packagesAllowedSlice;

export const { reducer: packagesAllowedReducer } = packagesAllowedSlice;
