import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPackagesPA } from '../services/getPackagesPA';
import { IPackagesPA, IPackagesPASchema } from '../types/package';

const initialState: IPackagesPASchema = {
  error: '',
  isLoading: false,
  packages: [],
};

export const packagesPASlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getPackagesPA.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getPackagesPA.fulfilled,
        (state, { payload }: PayloadAction<IPackagesPA[]>) => {
          state.isLoading = false;
          state.packages = payload;
        },
      )
      .addCase(getPackagesPA.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'packagesPA',
  reducers: {
    setErrorPackagesPA: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setIsLoadingPackagesPA: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPackagesPA: (state, { payload }: PayloadAction<IPackagesPA[]>) => {
      state.packages = payload;
    },
  },
});

export const {
  actions: { setErrorPackagesPA, setIsLoadingPackagesPA, setPackagesPA },
} = packagesPASlice;

export const { reducer: packagesPAReducer } = packagesPASlice;
