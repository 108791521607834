import { StateSchema } from '@/app-fsd/providers/StoreProvider';

export const getFormPaymentIsLoading = (state: StateSchema) =>
  state.formPayment.isLoadingFormPayment;

export const getErrorFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment;
export const getErrorTelFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment?.errorTel;
export const getErrorCodeFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment?.errorCode;
export const getErrorPackFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment?.errorPack;
export const getErrorEmailFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment?.errorEmail;
export const getErrorNameFormPayment = (state: StateSchema) =>
  state.formPayment?.errorFormPayment?.errorName;

export const getRequestFormPayment = (state: StateSchema) =>
  state.formPayment?.form;
export const getRequestPhoneFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.phoneNumber;
export const getRequestEmailFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.email;
export const getRequestNameFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.name;
export const getRequestPromoCodeFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.promoCode;
export const getRequestLicenseFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.isLicenseAllow;
export const getRequestAdvertFormPayment = (state: StateSchema) =>
  state.formPayment?.form?.isAdvertAllow;

export const getOldPricePromo = (state: StateSchema) =>
  state.formPayment?.promoCode?.oldPrice || '';
export const getPricePromo = (state: StateSchema) =>
  state.formPayment?.promoCode?.price || '';
