import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorsPayment, IFormRequest } from '../types/formPayment';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import { IUser, setDataUser } from '@/entities/User';
import urls from '@/shared/const/urls';
import { errorHandlerForThunk } from '@/shared/lib/errorHandlerForThunk/errorHandlerForThunk';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const fetchFormPayment = createAsyncThunk<
  IUser,
  IFormRequest,
  ThunkConfig<ErrorsPayment>
>('formPayment/formPayment', async (formData, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IUser>(urls.user.postUsers, formData);

    if (response.status >= 400) throw response.data;
    if (response.status >= 500) {
      const message = 'Неожиданная ошибка. Попробуйте еще раз';
      showSnackbar(message, 'error');
      // throw new Error(message);
    }

    dispatch(setDataUser(response.data));

    return response.data;
  } catch (error) {
    return errorHandlerForThunk(error, rejectWithValue, {
      errorPack: 'unknown error',
    });
  }
});
