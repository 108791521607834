import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFormPayment } from '../services/fetchFormPayment';
import { validatePromoCode } from '../services/validatePromoCode';
import {
  ErrorsPayment,
  FormPaymentSchema,
  IFormRequest,
  IPromoCodeResponse,
} from '../types/formPayment';
import { IUser } from '@/entities/User';

const initialState: FormPaymentSchema = {
  errorFormPayment: {},
  form: {
    isAdvertAllow: false,
    isLicenseAllow: false,
  },
  isLoadingFormPayment: false,
  promoCode: { oldPrice: '', price: '' },
};

export const formPaymentSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchFormPayment.pending, state => {
        state.errorFormPayment = undefined;
        state.isLoadingFormPayment = true;
      })
      .addCase(
        fetchFormPayment.fulfilled,
        (state, { payload }: PayloadAction<IUser>) => {
          state.isLoadingFormPayment = false;
        },
      )
      .addCase(
        fetchFormPayment.rejected,
        (state, { payload }: PayloadAction<ErrorsPayment | undefined>) => {
          state.isLoadingFormPayment = false;
          state.errorFormPayment = { ...state.errorFormPayment, ...payload };
        },
      )
      .addCase(validatePromoCode.pending, state => {
        state.errorFormPayment = undefined;
        state.isLoadingFormPayment = true;
      })
      .addCase(
        validatePromoCode.fulfilled,
        (state, { payload }: PayloadAction<IPromoCodeResponse>) => {
          state.isLoadingFormPayment = false;
        },
      )
      .addCase(
        validatePromoCode.rejected,
        (state, { payload }: PayloadAction<string | undefined>) => {
          state.isLoadingFormPayment = false;
          state.promoCode = { oldPrice: '', price: '' };
          state.errorFormPayment = {
            ...state.errorFormPayment,
            errorCode: payload,
          };
        },
      );
  },
  initialState,
  name: 'formPayment',
  reducers: {
    setErrorFormPayment: (state, { payload }: PayloadAction<ErrorsPayment>) => {
      state.errorFormPayment = { ...state.errorFormPayment, ...payload };
    },
    setPromoCode: (
      state,
      { payload }: PayloadAction<{ oldPrice: string; price: string }>,
    ) => {
      state.promoCode = payload;
    },
    updateForm: (state, { payload }: PayloadAction<IFormRequest>) => {
      state.form = { ...state.form, ...payload };
    },
  },
});

export const { setErrorFormPayment, updateForm, setPromoCode } =
  formPaymentSlice.actions;

export const { reducer: formPaymentReducer } = formPaymentSlice;
