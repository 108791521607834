import { LinkProps } from 'next/link';
import {
  AnchorHTMLAttributes,
  FC,
  ForwardedRef,
  ReactNode,
  forwardRef,
  useMemo,
} from 'react';
import cls from './links.module.css';
import { classNames } from '../../lib/classNames/classNames';
import { getRouteWelcome } from '@/shared/const/router';
import { AppLink, useAppPathname } from '@/shared/lib/router/navigation';
import { mapMarginsSize } from '@/shared/styles/const';
import { Margins } from '@/shared/types/ui';

type BaseLinkProps = Omit<LinkProps, 'locale'> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>;

export interface LinksProps extends BaseLinkProps {
  activeClassName?: string;
  className?: string;
  children: ReactNode;
  gap?: Margins;
  onClick?: any;
}

export const Links: FC<LinksProps> = forwardRef(
  (props, ref: ForwardedRef<HTMLAnchorElement>) => {
    const {
      href,
      activeClassName = '',
      className,
      children,
      gap,
      style,
      onClick,
      ...otherProps
    } = props;

    const pathname = useAppPathname();

    const currentTo = useMemo(() => {
      if (typeof href === 'number') return href as unknown as string;
      return href;
    }, [href]);

    const isActive = useMemo(
      () =>
        href === pathname || (href === getRouteWelcome() && pathname === ''),
      [pathname, href],
    );

    return (
      <AppLink
        ref={ref}
        href={currentTo}
        style={{ gap: gap ? `${mapMarginsSize[gap]}rem` : undefined, ...style }}
        className={classNames(cls.links, { [activeClassName]: isActive }, [
          className,
        ])}
        onClick={href ? undefined : onClick}
        {...otherProps}
      >
        {children}
      </AppLink>
    );
  },
);
