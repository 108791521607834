export {
  packageReducer,
  setSelectedPackages,
  setResetSelectedPackages,
  setFieldSelectedPackages,
  setSelectedProduct,
  setResetSelectedProduct,
} from './model/slices/package';
export type {
  PackageSchema,
  CompanyType,
  Package,
  Product,
  TradeInstruments,
  QueryParamsForAllPackages,
  TPATabNames,
  IPackagesPA,
  IPackagesPASchema,
} from './model/types/package';
export {
  getSelectedPackage,
  getPackageIsLoading,
  getSelectedProduct,
  getAllScreenerPackages,
  getAllSignalsPackages,
  getAllCopytradingPackages,
  getAllPromoSignalsPackages,
  getAllPromoScreenerPackages,
} from './model/selectors/getPackage';
export { fetchPackage } from './model/services/allPackages/fetchPackage';
export { productById } from './model/services/allPackages/productById';
export { discountPackages } from './model/services/discountPackages/discountPackages';

// PA
export {
  packagesPASlice,
  packagesPAReducer,
  setErrorPackagesPA,
  setIsLoadingPackagesPA,
  setPackagesPA,
} from './model/slices/packagesPA';
export { getPackagesPA } from './model/services/getPackagesPA';
export {
  getPackagesPAData,
  getPackagesPAError,
  getPackagesPAIsLoading,
} from './model/selectors/getPackagesPA';

// Allowed
export {
  packagesAllowedReducer,
  setErrorPackagesAllowed,
  setIsLoadingPackagesAllowed,
  setPackagesAllowed,
} from './model/slices/packagesAllowedSlice';
export {
  getPackagesAllowedIsLoading,
  getPackagesAllowedError,
  getPackagesAllowedData,
} from './model/selectors/getPackagesAllowed';
export { getPackagesAllowed } from './model/services/getPackagesAllowed';
