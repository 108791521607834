import { createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from '../../types/package';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const productById = createAsyncThunk<
  Product,
  number,
  ThunkConfig<string>
>('package/productById', async (id, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<Product>(
      urls.products.getProductById(id),
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
