import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPackagesPA } from '../types/package';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getPackagesAllowed = createAsyncThunk<
  IPackagesPA[],
  void,
  ThunkConfig<string>
>('packagesAllowed', async (_, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IPackagesPA[]>(
      urls.user.packagesAllowed,
    );
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
