import { StateSchema } from '@/app-fsd/providers/StoreProvider';

export const getPackagesAllowedIsLoading = (state: StateSchema) =>
  state.packagesAllowed.isLoading;

export const getPackagesAllowedError = (state: StateSchema) =>
  state.packagesAllowed?.error;

export const getPackagesAllowedData = (state: StateSchema) =>
  state.packagesAllowed.packages;
